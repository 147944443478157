<template>
  <div>
    <!-- <pre class="text-light">
      
      {{ getAllTrades }}

    </pre> -->

    <!-- DELETE DIALOG -->
    <base-dialog
      :show="!!showDeleteDialog"
      :show-ok="false"
      title="Delete trade?"
      @close="handleCloseShowDelete"
    >
      <div class="tx-light mb-2">
        Are you sure you want to delete trade
        <span class="text-warning"> {{ deleteTitleGuid }} ?</span>
        <div v-html="deleteTitleGuidDesc"></div>

        <div class="float-end mt-4">
          <base-button @click="handleCloseShowDelete">Cancel</base-button>
          <base-button @click="deleteIdActual">Delete</base-button>
        </div>

        <div>&nbsp;</div>
        <div>&nbsp;</div>
      </div>
    </base-dialog>

    <!-- <pre class="text-light">
  {{ getAllDividends }}
    </pre> -->

    <div class="accordion accordion-flush" id="accordionExample">
      <!-- IMPORT -->
      <div class="accordion-item bg-dark">
        <h2 class="accordion-header" id="headingOne">
          <button
            class="accordion-button collapsed bg-dark"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseOne"
            aria-expanded="false"
            aria-controls="collapseOne"
          >
            <h4 class="text-warning mt-1">Import</h4>
          </button>
        </h2>

        <div
          id="collapseOne"
          class="accordion-collapse collapse"
          aria-labelledby="headingOne"
        >
          <div class="accordion-body">
            <!-- TRADES IMPORT -->
            <div class="row mb-2">
              <div class="col-2">
                <base-button
                  type="button"
                  class="form-control"
                  @click="importTrades()"
                  >Import Trades</base-button
                >
              </div>

              <div class="col-10 text-light">
                Use format [Interactive Brokers -> Flex Queries -> Trades-new]:
                <div class="text-warning" v-html="escapedXmlTrades"></div>
              </div>
            </div>

            <!-- PRICES IMPORT -->
            <div class="row mb-2">
              <div class="col-2">
                <base-button
                  type="button"
                  class="form-control"
                  @click="importPrices()"
                  >Import Prices</base-button
                >
              </div>

              <div class="col-10 text-light">
                Copy from ETF-GoogleFinance -> page For COPY -> columns A,B,C to
                this textbox
              </div>
            </div>

            <!-- DIVIDENDS IMPORT -->
            <div class="row mb-2">
              <div class="col-2">
                <base-button
                  type="button"
                  class="form-control"
                  @click="importDividends()"
                  >Import Dividends</base-button
                >
              </div>

              <div class="col-10 text-light">
                Use format [Interactive Brokers -> Statements ->
                Dividende-Jernej] CSV:

                <div class="text-warning">
                  Dividends,Data,USD,2024-01-11,M3P(US58463J3041) Cash Dividend
                  USD 0.15 per Share (Ordinary Dividend),292.5,
                </div>

                <div class="text-warning">
                  CAUTION! Import for whole year!!!
                </div>
              </div>
            </div>

            <!-- PRICES ZACKS HTML TABLE -->
            <!-- <div class="row mb-2">
              <div class="col-2">
                <base-button
                  type="button"
                  class="form-control"
                  @click="importZacks()"
                  >Import ZACKS</base-button
                >
              </div>
            </div> -->

            <div class="col-3 align-items-start mb-2"></div>

            <textarea
              id="importarea"
              v-model="importText"
              style="width: 100%; height: 300px"
            ></textarea>
          </div>
        </div>
      </div>

      <!-- SEARCH -->
      <base-card>
        <h5 class="mb-3 ms-1">Search in both lists</h5>

        <div class="row mb-2 ms-2">
          <div class="col-6 px-0">
            <table>
              <tr>
                <td>
                  <base-text-search v-model="searchText"></base-text-search>
                </td>
                <td class="align-bottom">
                  <button
                    class="btn btn-danger btn-sm"
                    @click="searchText = ''"
                  >
                    <font-awesome-icon icon="fa-solid fa-solid fa-trash" />
                  </button>
                </td>
                <td>
                  <div class="form-check form-switch ms-3">
                    <label class="form-check-label" for="flexSwitchSymbolOnly"
                      >Search on symbol only</label
                    >
                    <input
                      id="flexSwitchSymbolOnly"
                      @click="searchOnSymbolOnly($event)"
                      class="form-check-input"
                      type="checkbox"
                      :checked="searchOnlySymbol"
                    />
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </div>

        <!-- <div class="container">
          <div class="row mb-2 align-items-end">
            <div class="col-3 text-end px-0">
              <base-text-search v-model="searchText"></base-text-search>
            </div>
            <div class="col-9 px-1">
              <button class="btn btn-danger btn-sm" @click="searchText = ''">
                <font-awesome-icon icon="fa-solid fa-solid fa-trash" />
              </button>
            </div>
          </div>
        </div> -->
      </base-card>

      <!-- DASHBOARD -->
      <div class="accordion-item bg-dark mt-2">
        <h2 class="accordion-header" id="headingTwo">
          <button
            class="accordion-button collapsed bg-dark"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseTwo"
            aria-expanded="false"
            aria-controls="collapseTwo"
          >
            <h4 class="text-warning mt-1">Dashboard</h4>
          </button>
        </h2>

        <div
          id="collapseTwo"
          class="accordion-collapse collapse"
          aria-labelledby="headingTwo"
        >
          <!-- DASHBOARD -->
          <div class="accordion-body">
            <!-- DATE AND PROFIT -->
            <base-card class="bg-dark text-warning">
              <section>
                <h5 class="mb-3 ms-1">Select date</h5>
                <base-card>
                  <base-date-picker
                    @ondatechange="ondatechangehere"
                    id="selectedDate"
                    :year-range="[2010, 2030]"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :model-value="priceDate"
                    :allowed-dates="allowedDates"
                  >
                  </base-date-picker>
                </base-card>
                <base-card class="bg-dark text-warning">
                  <div>
                    Sum Profit:&nbsp;&nbsp;<b :class="setclass(sumProfit)">{{
                      sumProfit
                    }}</b>
                  </div>
                </base-card>
              </section>
            </base-card>

            <base-card class="bg-dark">
              <!-- <h5 class="mb-3 ms-1">Dashboard</h5> -->
              <table class="table table-striped table-dark">
                <thead>
                  <tr class="text-warning">
                    <th>
                      <a class="jr-link" :href="null" @click="fOrder('symbol')"
                        >Symbol
                      </a>
                    </th>

                    <th>
                      <a
                        class="jr-link"
                        :href="null"
                        @click="fOrder('description')"
                        >Description
                      </a>
                    </th>

                    <th style="text-align: right">
                      <a
                        style="text-align: right"
                        class="jr-link"
                        :href="null"
                        @click="fOrder('sum')"
                        >Quantity</a
                      >
                    </th>

                    <th style="text-align: right">
                      <a
                        class="jr-link"
                        :href="null"
                        @click="fOrder('priceNow')"
                        >Price Now</a
                      >
                    </th>
                    <th style="text-align: right">
                      <a
                        class="jr-link"
                        :href="null"
                        @click="fOrder('valueNow')"
                        >Value Now</a
                      >
                    </th>
                    <th style="text-align: right">
                      <a
                        class="jr-link"
                        :href="null"
                        @click="fOrder('sumMoney')"
                        >Sum Buy</a
                      >
                    </th>

                    <th style="text-align: right">
                      <a class="jr-link" :href="null" @click="fOrder('profit')"
                        >Profit</a
                      >
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <trade-dashboard-item
                    v-for="item in getDashboard"
                    :key="item.symbol"
                    :index="item.symbol"
                    :symbol="item.symbol"
                    :description="item.description"
                    :sum="item.sum"
                    :sumMoney="item.sumMoney"
                    :valueNow="item.valueNow"
                    :profit="item.profit"
                    :priceNow="item.priceNow"
                  >
                  </trade-dashboard-item>

                  <tr>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td>&nbsp;</td>
                    <td :class="setclass(sumProfit)" style="text-align: right">
                      <b>{{ sumProfit }}</b>
                    </td>
                  </tr>
                </tbody>
              </table>
            </base-card>
          </div>
        </div>
      </div>

      <!-- TRADES -->
      <div class="accordion-item bg-dark mt-2">
        <h2 class="accordion-header" id="headingThree">
          <button
            class="accordion-button collapsed bg-dark"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapseThree"
            aria-expanded="false"
            aria-controls="collapseThree"
          >
            <h4 class="text-warning mt-1">Trades</h4>
          </button>
        </h2>

        <div
          id="collapseThree"
          class="accordion-collapse collapse"
          aria-labelledby="headingThree"
        >
          <!-- TRADES -->
          <div class="accordion-body">
            <base-card class="bg-dark">
              <section>
                <!-- <h5 class="mb-3 ms-1">Trades</h5> -->
                <!-- <base-card v-if="isLoading">
        <div v-if="isLoading">
          <base-spinner></base-spinner>
        </div>
      </base-card> -->

                <base-card class="bg-dark text-light">
                  <div>Number of records: {{ getAllTrades.length }}</div>
                </base-card>

                <!-- radio button SELL BUY -->
                <base-card class="text-warning">
                  <div>
                    <div class="row ms-1">
                      <div class="form-check form-switch col-sm-2">
                        <label class="form-check-label" for="flexSwitchSell"
                          >Show SELL Only</label
                        >
                        <input
                          id="flexSwitchSell"
                          @click="showOnlySell($event)"
                          class="form-check-input"
                          type="checkbox"
                          :checked="onlySellActive"
                        />
                      </div>

                      <div class="form-check form-switch col-sm-2">
                        <label class="form-check-label" for="flexSwitchBuy"
                          >Show BUY Only</label
                        >
                        <input
                          id="flexSwitchBuy"
                          @click="showOnlyBuy($event)"
                          class="form-check-input"
                          type="checkbox"
                          :checked="onlyBuyActive"
                        />
                      </div>

                      <div class="col-sm-3">
                        <label class="form-label" for="tradeYear"
                          >Date or Year</label
                        >
                        <input
                          id="tradeYear"
                          class="ms-2 col-sm-3"
                          v-model="tradeYear"
                        />&nbsp;&nbsp;Profit&nbsp;
                        {{
                          appUtils.formatCurrency(
                            getAllSums.sumAllProfit,
                            false
                          )
                        }}
                      </div>
                    </div>
                  </div>
                </base-card>
              </section>

              <table class="table table-striped table-dark">
                <thead>
                  <tr>
                    <th class="text-warning">Edit</th>

                    <th>
                      <a
                        class="jr-link"
                        :href="null"
                        @click="sortData('symbol', pageStore.setSortingTrade)"
                        >Symbol</a
                      >
                    </th>

                    <th>
                      <a
                        class="jr-link"
                        :href="null"
                        @click="
                          sortData('description', pageStore.setSortingTrade)
                        "
                        >Description</a
                      >
                    </th>

                    <th>
                      <a
                        class="jr-link"
                        :href="null"
                        @click="sortData('buySell', pageStore.setSortingTrade)"
                        >BuySell</a
                      >
                    </th>

                    <th>
                      <a
                        class="jr-link"
                        :href="null"
                        @click="
                          sortData('dateFormated', pageStore.setSortingTrade)
                        "
                        >Date</a
                      >
                    </th>

                    <th style="text-align: right">
                      <a
                        class="jr-link"
                        :href="null"
                        @click="sortData('quantity', pageStore.setSortingTrade)"
                        >Quantity</a
                      >
                    </th>

                    <th style="text-align: right">
                      <a
                        class="jr-link"
                        :href="null"
                        @click="
                          sortData('tradePrice', pageStore.setSortingTrade)
                        "
                        >Price</a
                      >
                    </th>

                    <th>
                      <a
                        class="jr-link"
                        :href="null"
                        @click="sortData('currency', pageStore.setSortingTrade)"
                        >Currency</a
                      >
                    </th>

                    <th style="text-align: right">
                      <a
                        class="jr-link"
                        :href="null"
                        @click="
                          sortData('tradeMoney', pageStore.setSortingTrade)
                        "
                        >Money</a
                      >
                    </th>

                    <th style="text-align: right">
                      <a
                        class="jr-link"
                        :href="null"
                        @click="
                          sortData('fifoPnlRealized', pageStore.setSortingTrade)
                        "
                        >Profit Realized</a
                      >
                    </th>

                    <th style="text-align: right">
                      <a
                        class="jr-link"
                        :href="null"
                        @click="
                          sortData('fxRateToBase', pageStore.setSortingTrade)
                        "
                        >FX rate</a
                      >
                    </th>

                    <th style="text-align: right">
                      <a
                        class="jr-link"
                        :href="null"
                        @click="
                          sortData('fxRateToBase', pageStore.setSortingTrade)
                        "
                        >Profit EUR</a
                      >
                    </th>

                    <th class="text-warning" style="text-align: right">
                      Delete
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <trade-item
                    @select-id="selectId"
                    @delete-id="deleteId"
                    v-for="item in getAllTrades"
                    :key="item.guid"
                    :index="item.guid"
                    :guid="item.guid"
                    :symbol="item.symbol"
                    :description="item.description"
                    :buy-sell="item.buySell"
                    :trade-date="item.tradeDate"
                    :quantity="item.quantity"
                    :trade-price="item.tradePrice"
                    :currency="item.currency"
                    :trade-money="appUtils.fixCurrency(item.tradeMoney)"
                    :date-formated="item.dateFormated"
                    :fifo-pnl-realized="
                      appUtils.fixCurrency(item.fifoPnlRealized)
                    "
                    :fx-rate-to-base="item.fxRateToBase"
                    :show-active="'0'"
                    :selectedguid="getSelectedGuid"
                    :is-delete-visible="true"
                  ></trade-item>
                  <!-- v-model:active="item.active" -->

                  <tr>
                    <td colspan="6" style="text-align: right">
                      {{ getAllSums.sumAllQuantity }}
                    </td>

                    <td style="text-align: right">
                      {{ getAllSums.sumAvarageBuyPrice }}
                    </td>

                    <td colspan="2" style="text-align: right">
                      {{ getAllSums.sumAllMoney }}
                    </td>
                    <td style="text-align: right">
                      {{ getAllSums.sumAllProfit }}
                    </td>
                    <td style="text-align: right">&nbsp;</td>

                    <td style="text-align: right">
                      {{ getAllSums.sumAllProfitEur }}
                    </td>

                    <td style="text-align: right">&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </base-card>
          </div>
        </div>
      </div>

      <!-- DIVIDENDS -->
      <div class="accordion-item bg-dark mt-2">
        <h2 class="accordion-header" id="heading4">
          <button
            class="accordion-button collapsed bg-dark"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#collapse4"
            aria-expanded="false"
            aria-controls="collapse4"
          >
            <h4 class="text-warning mt-1">Dividends</h4>
          </button>
        </h2>

        <div
          id="collapse4"
          class="accordion-collapse collapse"
          aria-labelledby="heading4"
        >
          <!-- DIVIDENDS -->
          <div class="accordion-body">
            <base-card class="bg-dark">
              <section class="ms-2">
                <base-year-selector
                  @changeYearComponent="changeYearParent"
                ></base-year-selector>
              </section>

              <section>
                <base-card class="bg-dark text-light">
                  <div>
                    Number of records: {{ Object.keys(getAllDividends).length }}
                  </div>
                </base-card>
              </section>

              <table class="table table-striped table-dark">
                <thead>
                  <tr class="text-warning">
                    <th>
                      <a
                        class="jr-link"
                        :href="null"
                        @click="
                          sortData('symbol', pageStore.setSortingDividend)
                        "
                        >Symbol</a
                      >
                    </th>

                    <th>
                      <a
                        class="jr-link"
                        :href="null"
                        @click="sortData('date', pageStore.setSortingDividend)"
                        >Dividend Date</a
                      >
                    </th>

                    <th style="text-align: right">
                      <a
                        class="jr-link"
                        :href="null"
                        @click="
                          sortData('dividend', pageStore.setSortingDividend)
                        "
                        >Amount</a
                      >
                    </th>
                    <th style="text-align: right">
                      <a
                        class="jr-link"
                        :href="null"
                        @click="
                          sortData('currency', pageStore.setSortingDividend)
                        "
                        >Currency</a
                      >
                    </th>
                    <th>
                      <a
                        class="jr-link"
                        :href="null"
                        @click="
                          sortData('description', pageStore.setSortingDividend)
                        "
                        >Description</a
                      >
                    </th>

                    <th>
                      <a
                        class="jr-link"
                        :href="null"
                        @click="
                          sortData('imported', pageStore.setSortingDividend)
                        "
                        >Imported</a
                      >
                    </th>
                  </tr>
                </thead>

                <tbody>
                  <dividend-item
                    v-for="item in getAllDividends"
                    :key="item.guid"
                    :index="item.guid"
                    :guid="item.guid"
                    :symbol="item.symbol"
                    :description="item.description"
                    :currency="item.currency"
                    :date="item.date"
                    :dividend="item.dividend"
                    :imported="item.imported"
                  ></dividend-item>

                  <tr>
                    <td colspan="2">&nbsp;</td>
                    <td style="text-align: right">
                      {{ getAllSums.sumDividend }}
                    </td>
                    <td colspan="3">&nbsp;</td>
                  </tr>
                </tbody>
              </table>
            </base-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import moment from 'moment';
import { ref, computed, onMounted } from 'vue';
import appUtils from '../../components/utils/appUtils';

import {
  saveToFirebaseWithOptions,
  deleteFromFirebase,
} from '../../components/utils/firebaseUtils';
import TradeItem from '../../components/invest/TradeItem.vue';
import DividendItem from '../../components/invest/DividendItem.vue';
import TradeDashboardItem from '../../components/invest/TradeDashboardItem.vue';

import { useInvestStore } from '../../stores/InvestStore';
import { loadFromFirebasePinia } from '../../components/utils/firebaseUtils';

const pageStore = useInvestStore();
let importText = ref('');
const deleteTitleGuid = ref('');
const deleteTitleGuidDesc = ref('');
const dataSorting = ref('');
const dataSortingOrder = ref('asc');
const selectedguid = ref('');
const sortOrder = ref('asc');
const searchText = ref(null);
const showDeleteDialog = ref(null);
let pricesRef = ref([]);
let pricesAll = ref([]);
let sortField = ref('symbol');
let priceDate = ref();
let allowedDates = ref();

let tradeYear = ref(appUtils.getCurrentYear());

//const polygon_io_APIKEY = 'icmJf9pRcrRAIMnNVYTGynXiaY8Xgb4J';
// const selectedDate = ref(
//   appUtils.getCurrentYearMonth() +
//     '-' +
//     String(new Date().getDate()).padStart(2, '0')
// );
let onlySellActive = ref(false);
let onlyBuyActive = ref(false);
let searchOnlySymbol = ref(true);

const minDate = '2020-01-01';
const maxDate = appUtils.getCurrentYear(1) + '-12-31';
//const maxDate =  '2025-12-31';

const xmlLine = ref(
  '<Trade buySell="BUY" symbol="IWDA" tradeDate="28/03/2024" quantity="3.2814" tradePrice="91.423488" currency="EUR" tradeMoney="299.997033523" description="ISHARES CORE MSCI WORLD" dateTime="28/03/2024;12:20:46" netCash="-302.997033523" listingExchange="AEB" ibCommission="-3" ibCommissionCurrency="EUR" fxRateToBase="1" fifoPnlRealized="0" />'
);
const escapedXmlTrades = ref(
  xmlLine.value.replace(/</g, '&lt;').replace(/>/g, '&gt;')
);

// const xmlDividends = ref(
//   '<OpenDividendAccrual currency="USD" symbol="BEP" description="BROOKFIELD RENEWABLE PARTNER" isin="BMG162581083" issuerCountryCode="CA" payDate="27/09/2024" tax="0" fee="0" grossRate="0.34627" grossAmount="20.78" netAmount="20.78" />'
// );

// const escapedXmlDividends = ref(
//   xmlDividends.value.replace(/</g, '&lt;').replace(/>/g, '&gt;')
// );

onMounted(() => {
  console.log('onMounted');

  getAllPrices().then((snapshot) => {
    console.log(snapshot);
    let tmpObj = appUtils.getObjectCopy(snapshot.val());
    console.log(tmpObj);
    pricesAll.value = tmpObj;
    allowedDates.value = Object.keys(pricesAll.value);
    priceDate.value = allowedDates.value[allowedDates.value.length - 1];

    pricesRef.value = pricesAll.value[priceDate.value];
    // getPrices().then((snapshot) => {
    //   console.log(snapshot);
    //   let tmpObj = appUtils.getObjectCopy(snapshot.val());
    //   console.log(tmpObj);
    //   prices.value = tmpObj;
    // });

    // console.log(getPricesComp);
  });

  // pageStore.loadDividendsFirebase(null).then((snapshot) => {
  //   console.log(snapshot);
  // });

  //console.clear();
  // fetch(
  //   `https://api.polygon.io/v2/aggs/ticker/AAPL/prev?adjusted=true&apiKey=${polygon_io_APIKEY}`
  // )
  //   .then((response) => response.json())
  //   .then((response) => successx(response));
});

function changeYearParent(parYear) {
  console.log('changeYearParent:', parYear);
  pageStore.loadDividendsFirebase(null, parYear);
}

// function successx(response) {
//   var objString = JSON.stringify(response, null, 3);
//   console.log(response.results[0].c);
//   console.log(response);
//   console.log(objString);
// }

// const getPricesComputed = computed(function () {
//   return prices;
// });

const getDashboard = computed(function () {
  let uniqueSymbol = pageStore.getUniqueSymbols();
  let tmpTradesWithGuid = pageStore.tradesWithGuid;
  let dashboard = [];
  let sum = 0;
  let sumMoney = 0;
  let sumProfitRealized = 0;

  console.log('================ uniqueSymbol ================');
  console.log(uniqueSymbol);

  uniqueSymbol.forEach((item) => {
    sum = 0;
    sumMoney = 0;
    sumProfitRealized = 0;
    for (var i = 0; i < tmpTradesWithGuid.length; i++) {
      if (item.symbol == tmpTradesWithGuid[i].symbol) {
        sum += tmpTradesWithGuid[i].quantity;
        sumMoney += tmpTradesWithGuid[i].tradeMoney;
        sumProfitRealized += tmpTradesWithGuid[i].fifoPnlRealized;
      }
    }

    //sum > 0.000001 because of rounding - sometimes we ge some small sum like 1*e-14
    if (sum != 0 && sum > 0.000001) {
      let valueNow = 0;
      let priceNow = 0;

      if (pricesRef.value[item.symbol]) {
        try {
          valueNow = appUtils.fixCurrency(
            pricesRef.value[item.symbol].price * sum

            //getPricesComputed.value.value[item.symbol].price * sum
          );
          //        priceNow = getPricesComputed.value.value[item.symbol].price;
          priceNow = pricesRef.value[item.symbol].price;
        } catch (error) {
          console.log(error);
        }
        // } else {
        //   console.log('UNDEFINED', item.symbol);
      }

      dashboard.push({
        description: item.description,
        symbol: item.symbol,
        sum: appUtils.fixCurrency(sum, 4),
        sumMoney: appUtils.fixCurrency(sumMoney),
        valueNow,
        profit: appUtils.fixCurrency(valueNow - sumMoney - sumProfitRealized),
        priceNow,
      });
    }
  });

  if (searchText.value)
    dashboard = fastFilterAll(dashboard, searchText.value, [
      'description',
      'symbol',
    ]);

  console.log(' ============= dashboard =============');
  console.log(dashboard);

  return getSortedDashboard(dashboard);
});

const sumProfit = computed(function () {
  let dashboard = getDashboard;
  let sumProfit = 0;
  dashboard.value.forEach((item) => {
    sumProfit += item.profit;
  });
  sumProfit = appUtils.fixCurrency(sumProfit);

  return sumProfit;
});

const getSelectedGuid = computed(function () {
  if (selectedguid.value) {
    return selectedguid.value;
  } else {
    return '';
  }
});

const getAllTrades = computed(function () {
  let tmp = pageStore.tradesWithGuid;

  if (onlySellActive.value == true) {
    tmp = fastFilterAllGrids(['buySell'], tmp, 'SELL');
  } else if (onlyBuyActive.value == true) {
    tmp = fastFilterAllGrids(['buySell'], tmp, 'BUY');
  }
  if (searchText.value) {
    let fieldArr = [
      'description',
      'symbol',
      'buySell',
      'currency',
      'tradeDate',
    ];
    tmp = fastFilterAll(tmp, searchText.value, fieldArr);
  }
  if (tradeYear.value) {
    let fieldArr = ['tradeDate'];
    tmp = fastFilterAll(
      tmp,
      tradeYear.value.replace('.', '/').replace('.', '/'),
      fieldArr
    );
  }

  return tmp;
});

// const getAllMoney = computed(function () {
//   //calculate sum of Money for all trades in filtered list
//   let tmp = getAllTrades;
//   let sumAllMoney = 0;
//   console.log(tmp);
//   tmp.value.forEach((item) => {
//     sumAllMoney += item.tradeMoney;
//   });

//   return appUtils.fixCurrency(sumAllMoney);
// });

// const getAllProfitRealized = computed(function () {
//   //calculate sum of fifoPnlRealized for all trades in filtered list
//   let tmp = getAllTrades;
//   let sumAllProfit = 0;
//   console.log(tmp);
//   tmp.value.forEach((item) => {
//     sumAllProfit += item.fifoPnlRealized;
//   });

//   return appUtils.fixCurrency(sumAllProfit);
// });

const getAllSums = computed(function () {
  //calculate sum of fifoPnlRealized for all trades in filtered list
  let tmp = getAllTrades;
  let sumAllProfit = 0;
  let sumAllProfitEur = 0;
  let sumAllMoney = 0;
  let sumAllQuantity = 0;
  let sumAllBuy = 0;
  //let sumAllSell = 0;
  let sumAllBuyQty = 0;
  //let sumAllSellQty = 0;

  let sumAllDividend = 0;

  console.log(tmp);
  tmp.value.forEach((item) => {
    if (item.symbol == 'M3P' && item.buySell == 'SELL') console.log(item);

    sumAllProfit += item.fifoPnlRealized;
    sumAllProfitEur += item.fifoPnlRealized * item.fxRateToBase;
    sumAllMoney += item.tradeMoney;
    sumAllQuantity += item.quantity;
    //
    if (item.buySell == 'BUY') {
      sumAllBuyQty += item.quantity;
      sumAllBuy += item.tradeMoney - item.ibCommission;
      //sumAllBuy -= item.ibCommission;
    } else if (item.buySell == 'SELL') {
      //sumAllSellQty += item.quantity;
      //sumAllSell += item.tradeMoney;
      //sumAllBuy -= item.ibCommission;
    }
  });

  //geting sum of dividends
  let tmp1 = getAllDividends;
  tmp1.value.forEach((item) => {
    sumAllDividend += item.dividend;
  });

  let retObj = {
    sumAllProfit: appUtils.fixCurrency(sumAllProfit),
    sumAllProfitEur: appUtils.fixCurrency(sumAllProfitEur),
    sumAllMoney: appUtils.fixCurrency(sumAllMoney),
    sumAllQuantity: appUtils.fixCurrency(sumAllQuantity, 4),
    sumAvarageBuyPrice: appUtils.fixCurrency(sumAllBuy / sumAllBuyQty),
    sumDividend: appUtils.fixCurrency(sumAllDividend),
  };
  // console.log(sumAllBuyQty);
  // console.log(sumAllSellQty);
  // console.log(sumAllBuy);
  // console.log(sumAllSell);
  // console.log(retObj);

  return retObj;
});

function selectId(parObj) {
  console.log(parObj);
  selectedguid.value = parObj.guid;
  //   description.value = parObj.description;
  //   symbol.value = parObj.symbol;
  //   order.value = parObj.order;
  //   type.value = parObj.type;
  //   activeModal.value = parObj.active;
  //   modalTransactionCaption.value = 'Edit Symbol [' + parObj.guid + ']';
}

function getImportDatetime() {
  return 'IMPORT ' + moment().format('YYYY.MM.DD HH:mm:ss');
}

/* IMPORT DIVIDENDS*/
async function importDividends() {
  subImportDividends2()
    .then(() => {
      pageStore
        .loadDividendsFirebase(null, appUtils.getCurrentYear())
        .then((snapshot) => {
          console.log(snapshot);
        });

      // getAllDividendsFirebase().then((snapshot) => {
      //   // console.log(snapshot);
      //   let tmpObj = appUtils.getObjectCopy(snapshot.val());
      //   console.log(tmpObj);
      //   console.log('importDividends');
      // });

      appUtils.showToast(`Dividends imported!`);
    })
    .catch((error) => {
      throw error.message;
    });
}

const getAllDividends = computed(function () {
  let tmp = pageStore.dividendsWithGuid;
  console.log('************ getAllDividends ***********');
  //console.log(tmp);
  //if (tmp) console.log(tmp.value.length);
  //tmp = tmp['2024'];
  //if (searchText.value) tmp = fastFilterTrades(tmp, searchText.value);

  if (searchText.value)
    tmp = fastFilterAll(tmp, searchText.value, ['description', 'symbol']);

  return tmp;
});

// function getAllDividendsFirebase() {
//   return loadFromFirebasePinia({}, 'invest_dividends', null, '2024');
// }

async function subImportDividends2() {
  try {
    var lines = importText.value.split('\n');
    //var tmpDate = moment().format('YYYY');
    var tmpLine = lines[0].split(',');
    var tmpDate = tmpLine[3].substring(0, 4);

    let optionObj = { url: `invest_dividends/{uid}/${tmpDate}` };
    let saveObj = {};
    let keys = [];

    for (let i = 0; i < lines.length; i++) {
      if (lines[i] != '') {
        var tmp = lines[i].split(',');
        if (tmp.length > 0) {
          var tmpSymbol = tmp[4];
          tmpSymbol = tmpSymbol.substring(0, tmpSymbol.indexOf('('));
          tmpSymbol = tmpSymbol.trim();
          var key = tmpSymbol + '-' + tmp[3];
          if (keys.includes(key)) {
            //key already exists we modity it
            key = tmpSymbol + '-' + i.toString() + '-' + tmp[3];
          }
          keys.push(key);

          saveObj[key] = {
            symbol: tmpSymbol,
            date: tmp[3],
            dividend: Number(tmp[5].replace(',', '')),
            currency: tmp[2],
            description: tmp[4],
            imported: getImportDatetime(),
          };

          saveToFirebaseWithOptions(optionObj, saveObj).then(() => {});
        }
      }
    }

    console.log('subImportDividends2 => saveObj');
    console.log(optionObj);
    console.log(saveObj);
    //saveObj.importTime = getImportDatetime();

    // saveToFirebaseWithOptions(optionObj, saveObj).then(() => {
    //   appUtils.showToast(
    //     `Dividends imported [count=${Object.keys(saveObj).length}] !`
    //   );

    //   getAllDividendsFirebase().then((snapshot) => {
    //     // console.log(snapshot);
    //     let tmpObj = appUtils.getObjectCopy(snapshot.val());
    //     console.log(tmpObj);
    //     // pricesAll.value = tmpObj;
    //     // allowedDates.value = Object.keys(pricesAll.value);
    //     // priceDate.value = allowedDates.value[allowedDates.value.length - 1];
    //     // pricesRef.value = pricesAll.value[priceDate.value];
    //   });
    // });
  } catch (error) {
    console.log(error);
  }
}

// async function subImportDividends() {
//   const parser = new DOMParser();
//   const xmlDoc = parser.parseFromString(importText.value, 'text/xml');
//   const xmlDividends = getDividendsFromXML(xmlDoc);

//   var dividendNodes = xmlDividends.childNodes;
//   let optionObj = { url: `invest_dividends/{uid}/{newKey}` };

//   let saveObj;
//   for (var element in dividendNodes) {
//     if (dividendNodes[element].tagName == 'OpenDividendAccrual') {
//       if (
//         dividendNodes[element].attributes.symbol.value != 'EUR.USD' &&
//         dividendNodes[element].attributes.symbol.value != 'USD.EUR'
//       ) {
//         saveObj = getSaveObjDividends(dividendNodes[element].attributes);
//         // console.log(saveObj);
//         saveObj.importTime = getImportDatetime();
//         //pageStore.loadInvestFirebase
//         saveToFirebaseWithOptions(optionObj, saveObj).then(() => {
//           console.log('IMPORT subImportDividends DONE');
//         });
//       }
//     }
//   }
// }

// function getSaveObjDividends(pAttributes) {
//   let saveObj = {};

//   saveObj.currency = pAttributes.currency.value;
//   saveObj.symbol = pAttributes.symbol.value;
//   saveObj.description = pAttributes.description.value;
//   saveObj.isin = pAttributes.isin.value;
//   saveObj.issuerCountryCode = pAttributes.issuerCountryCode.value;
//   saveObj.payDate = pAttributes.payDate.value;
//   saveObj.payDateFormated = appUtils.getFormatedDate2(
//     saveObj.payDate,
//     '/',
//     '-'
//   );
//   saveObj.tax = Number(pAttributes.tax.value);
//   saveObj.fee = Number(pAttributes.fee.value);
//   saveObj.grossRate = Number(pAttributes.grossRate.value);
//   saveObj.grossAmount = Number(pAttributes.grossAmount.value);
//   saveObj.netAmount = Number(pAttributes.netAmount.value);

//   return saveObj;
// }

// function getDividendsFromXML(xmlDoc) {
//   let xmlTrades;
//   const parentTag = xmlDoc.getElementsByTagName('FlexStatements')[0];
//   if (parentTag) {
//     const childTag = parentTag.getElementsByTagName('FlexStatement')[0];
//     if (childTag)
//       xmlTrades = childTag.getElementsByTagName('OpenDividendAccruals')[0];
//   }
//   return xmlTrades;
// }

/* IMPORT TRADES */
async function importTrades() {
  subImportTrades()
    // .then(() => emit('importdone'))
    .then(() => {
      console.log('importdone');
      appUtils.showToast(`Trades imported!`);
    })
    .catch((error) => {
      throw error.message;
    });
}

function getTradesFromXML(xmlDoc) {
  let xmlTrades;
  const parentTag = xmlDoc.getElementsByTagName('FlexStatements')[0];
  if (parentTag) {
    const childTag = parentTag.getElementsByTagName('FlexStatement')[0];
    if (childTag) xmlTrades = childTag.getElementsByTagName('Trades')[0];
  }
  return xmlTrades;
}

async function subImportTrades() {
  //console.log(tmpRemark);
  const parser = new DOMParser();
  const xmlDoc = parser.parseFromString(importText.value, 'text/xml');
  const xmlTrades = getTradesFromXML(xmlDoc);

  var tradeNodes = xmlTrades.childNodes;
  let optionObj = { url: `invest_trades/{uid}/{newKey}` };

  let saveObj;
  for (var element in tradeNodes) {
    if (tradeNodes[element].tagName == 'Trade') {
      if (
        tradeNodes[element].attributes.symbol.value != 'EUR.USD' &&
        tradeNodes[element].attributes.symbol.value != 'USD.EUR'
      ) {
        saveObj = getSaveObjTrades(tradeNodes[element].attributes);
        // console.log(saveObj);
        saveObj.importTime = getImportDatetime();
        saveToFirebaseWithOptions(
          optionObj,
          saveObj,
          pageStore.loadInvestFirebase
        ).then(() => {
          console.log('IMPORT DONE');
        });
      }
    }
  }
}

function getSaveObjTrades(pAttributes) {
  let saveObj = {};

  saveObj.buySell = pAttributes.buySell.value;
  saveObj.symbol = pAttributes.symbol.value;
  saveObj.tradeDate = pAttributes.tradeDate.value;
  saveObj.quantity = Number(pAttributes.quantity.value);
  saveObj.tradePrice = Number(pAttributes.tradePrice.value);
  saveObj.currency = pAttributes.currency.value;
  saveObj.tradeMoney = Number(pAttributes.tradeMoney.value);
  saveObj.dateTime = pAttributes.dateTime.value;
  saveObj.description = pAttributes.description.value;
  saveObj.netCash = Number(pAttributes.netCash.value);
  saveObj.listingExchange = pAttributes.listingExchange.value;
  saveObj.ibCommission = Number(pAttributes.ibCommission.value);
  saveObj.ibCommissionCurrency = pAttributes.ibCommissionCurrency.value;
  saveObj.fxRateToBase = Number(pAttributes.fxRateToBase.value);
  saveObj.fifoPnlRealized = Number(pAttributes.fifoPnlRealized.value);

  //saveObj.dateFormated = appUtils.getDateAsString(saveObj.tradeDate);

  saveObj.dateFormated = appUtils.getFormatedDate2(saveObj.tradeDate, '/', '-');

  saveObj.symbol = saveObj.symbol.replace('CNDX', 'SXRV');
  saveObj.symbol = saveObj.symbol.replace('R2US', 'ZPRR');
  saveObj.symbol = saveObj.symbol.replace('CSPX', 'SXR8');
  saveObj.symbol = saveObj.symbol.replace('VNAd', 'VNA');

  return saveObj;
}

function fastFilterAllGrids(fieldArr, tmp, searchText) {
  return tmp.filter(
    appUtils.fastFilterGeneral.bind(this, searchText, fieldArr)
  );
}

function fastFilterAll(tmp, searchText, fieldArr) {
  console.log('fastFilterAll=>searchText', searchText);

  if (searchOnlySymbol.value == true) {
    fieldArr = ['symbol'];
    searchText = searchText + '#EXACT#';
  }
  return fastFilterAllGrids(fieldArr, tmp, searchText);
}

function handleCloseShowDelete() {
  showDeleteDialog.value = null;
  deleteTitleGuid.value = null;
}

function deleteId(transGuid) {
  showDeleteDialog.value = true;

  console.log(transGuid);

  deleteTitleGuid.value = transGuid;
  //   deleteTitleGuidDesc.value = '<br />';

  let tmpTradesWithGuid = pageStore.tradesWithGuid;

  for (var i = 0; i < tmpTradesWithGuid.length; i++) {
    if (tmpTradesWithGuid[i].guid == transGuid) {
      //find the trade and show details in dialog
      console.log(tmpTradesWithGuid[i]);

      deleteTitleGuidDesc.value = '<br />';
      deleteTitleGuidDesc.value += appUtils.formatTextForDialog(
        tmpTradesWithGuid[i].symbol,
        'Symbol'
      );

      deleteTitleGuidDesc.value += appUtils.formatTextForDialog(
        tmpTradesWithGuid[i].description,
        'Description'
      );
      deleteTitleGuidDesc.value += appUtils.formatTextForDialog(
        tmpTradesWithGuid[i].buySell,
        'BuySell'
      );
      deleteTitleGuidDesc.value += appUtils.formatTextForDialog(
        tmpTradesWithGuid[i].tradeDate,
        'Date',
        true
      );
      deleteTitleGuidDesc.value += appUtils.formatTextForDialog(
        tmpTradesWithGuid[i].quantity,
        'Quantity'
      );
      deleteTitleGuidDesc.value += appUtils.formatTextForDialog(
        tmpTradesWithGuid[i].tradePrice,
        'Price'
      );
      deleteTitleGuidDesc.value += appUtils.formatTextForDialog(
        tmpTradesWithGuid[i].currency,
        'Currency'
      );
    }
  }
}

function deleteIdActual() {
  const X = window.scrollX;
  const Y = window.scrollY;
  if (deleteTitleGuid.value) {
    let optionObj = { url: `invest_trades/{uid}/${deleteTitleGuid.value}` };

    deleteFromFirebase(optionObj, pageStore.loadInvestFirebase).then(() => {
      //scrolling back to previous position
      window.scrollTo(X, Y);
      appUtils.showToast('Trade Deleted');
    });
  }
  handleCloseShowDelete();
}

function fOrder(field) {
  if (field == sortField.value) {
    if (sortOrder.value == 'asc') sortOrder.value = 'desc';
    else sortOrder.value = 'asc';
  } else sortOrder.value = 'asc';
  sortField.value = field;
}

function sortData(field, pageStoreAction, reload = false) {
  console.log(
    `sortData | field=${field} | dataSorting=${dataSorting.value} | dataSortingOrder=${dataSortingOrder.value}`
  );

  let callSorting = false;

  if (reload) {
    if (field == dataSorting.value) {
      if (dataSortingOrder.value == 'asc') {
        dataSortingOrder.value = 'desc';
      } else {
        dataSortingOrder.value = 'asc';
      }
      callSorting = true;
    }
  } else {
    callSorting = true;
  }

  if (callSorting)
    appUtils.setSortingParamsProto(
      field,
      dataSorting,
      dataSortingOrder,
      pageStoreAction
    );
}

// function setSortingDividend(field, pageStoreAction) {
//   console.clear;
//   console.log(field);

//   appUtils.setSortingParamsProto(
//     field,
//     dataSorting,
//     dataSortingOrder,
//     pageStoreAction
//   );
// }

function getSortedDashboard(dashboard) {
  if (sortField.value == 'symbol') {
    return dashboard.sort((a, b) =>
      appUtils.sortByKey1(a, b, sortField.value, sortOrder.value)
    );
  } else {
    if (sortOrder.value == 'asc') {
      dashboard.sort(function (a, b) {
        return a[sortField.value] - b[sortField.value];
      });
    } else {
      dashboard.sort(function (a, b) {
        return b[sortField.value] - a[sortField.value];
      });
    }
  }
  return dashboard;
}

async function importPrices() {
  try {
    var lines = importText.value.split('\n');
    var tmpDate = moment().format('YYYY-MM-DD');
    //tmpDate = '2024-12-31';

    let optionObj = {
      url: `invest_prices/{uid}/${tmpDate}`,
    };

    let dontImport = ['ETHE', 'MATIC', 'MMAT', 'OMG', 'SAND'];

    let saveObj = {};
    for (let i = 0; i < lines.length; i++) {
      if (lines[i] != '') {
        var tmp = lines[i].split('\t');
        if (tmp.length > 0 && !tmp[1].includes('N/A')) {
          var tmpSymbol = tmp[0].replace('.', '-');

          if (dontImport.includes(tmpSymbol) == false) {
            saveObj[tmpSymbol] = {
              price: Number(tmp[1].replace(',', '')),
              currency: tmp[2],
            };
          }
        }
      }
    }

    // console.log('subImportPrices => saveArr');
    // console.log(saveArr);

    saveToFirebaseWithOptions(optionObj, saveObj).then(() => {
      appUtils.showToast(
        `Prices imported [count=${Object.keys(saveObj).length}] !`
      );

      getAllPrices().then((snapshot) => {
        // console.log(snapshot);
        let tmpObj = appUtils.getObjectCopy(snapshot.val());
        // console.log(tmpObj);
        pricesAll.value = tmpObj;
        allowedDates.value = Object.keys(pricesAll.value);
        priceDate.value = allowedDates.value[allowedDates.value.length - 1];
        pricesRef.value = pricesAll.value[priceDate.value];
      });
    });
  } catch (error) {
    console.log(error);
  }
}

function setclass(profit) {
  if (profit > 0) return 'bg-profit';
  return 'bg-profit-minus';
}

// function getPrices() {
//   return loadFromFirebasePinia({}, 'invest_prices', null, priceDate.value);
// }

function getAllPrices() {
  return loadFromFirebasePinia({}, 'invest_prices');
}

// function getAllDividendsFirebase() {
//   return loadFromFirebasePinia({}, 'invest_dividends');
// }

function ondatechangehere(eventArgs) {
  priceDate.value = appUtils.getDateAsString(eventArgs);
  pricesRef.value = pricesAll.value[priceDate.value];
}

function showOnlySell() {
  onlySellActive.value = !onlySellActive.value;
  if (onlySellActive.value == true) onlyBuyActive.value = false;
}

function showOnlyBuy() {
  onlyBuyActive.value = !onlyBuyActive.value;
  if (onlyBuyActive.value == true) onlySellActive.value = false;
}

function searchOnSymbolOnly() {
  searchOnlySymbol.value = !searchOnlySymbol.value;
}

// function importZacks() {
//   console.clear();

//   console.log('ZACKS');

//   var lines = importText.value.split('\n');
//   // var tmpLine = lines[0].split(',');
//   // var tmpDate = tmpLine[3].substring(0, 4);
//   // let optionObj = { url: `invest_dividends/{uid}/${tmpDate}` };

//   let startTagIndex = 0;
//   let endTagIndex = 0;
//   let saveObj = {};
//   let tmp;
//   for (let i = 0; i < lines.length; i++) {
//     if (lines[i].includes('Value Score')) {
//       tmp = lines[i + 1];
//       tmp = tmp.substring(tmp.indexOf('<th>') + '<th>'.length);
//       tmp = tmp.substring(0, tmp.indexOf(':'));
//       saveObj.symbol = tmp;
//     } else if (
//       lines[i].includes('<td class="alpha">') &&
//       lines[i + 1].includes('PEG Ratio</a>')
//     ) {
//       tmp = lines[i + 1];
//       saveObj.peg = tmp;
//       startTagIndex = i;
//       endTagIndex = findString(lines, '</tr>', startTagIndex);
//       console.log(startTagIndex);
//       console.log(endTagIndex);

//       tmp = lines[endTagIndex - 5].trim();
//       tmp = tmp.replace('<td>', '');
//       tmp = tmp.replace('</td>', '');
//       tmp = tmp.substring(tmp.indexOf('>') + 1);
//       tmp = tmp.replace('</a>', '');
//       saveObj.peg = tmp;
//     }
//   }

//   console.log(saveObj);
// }

// function findString(lines, searchString, startAt) {
//   let ret = 0;
//   for (let i = startAt; i < lines.length; i++) {
//     if (lines[i].trim() == searchString) {
//       ret = i;
//       break;
//     }
//   }

//   return ret;
// }
</script>

<style>
.active1 {
  background-color: blue;
  color: white;
}
</style>
