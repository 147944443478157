<template>
  <tr :id="guid" :class="setclass()">
    <td>
      <button
        class="btn btn-sm bg-warning"
        @click="setSelectedId($event, guid)"
        data-bs-toggle="modal"
        data-bs-target="#modaltransaction"
      >
        <font-awesome-icon icon="fa-pen-to-square" />
      </button>
    </td>
    <!-- <td v-show="showActive == 1">
      <div class="col-sm-8 form-check form-switch">
        <input
          disabled
          @click="toggleRadio($event)"
          class="form-check-input"
          type="checkbox"
          :checked="active"
        />
      </div>
    </td> -->

    <!-- <td :class="setclass()">{{ guid }}</td> -->

    <td :class="setclass()">{{ symbol }}</td>
    <td :class="setclass()">{{ description }}</td>
    <td :class="setclass()">{{ buySell }}</td>
    <td :class="setclass()">{{ getFormatedDate }}</td>
    <td :class="setclass()" style="text-align: right">{{ quantity }}</td>
    <td :class="setclass()" style="text-align: right">{{ tradePrice }}</td>
    <td :class="setclass()">{{ currency }}</td>
    <td :class="setclass()" style="text-align: right">
      {{ tradeMoney }}
    </td>
    <td :class="setclass()" style="text-align: right">
      {{ fifoPnlRealized }}
    </td>

    <td :class="setclass()" style="text-align: right">{{ fxRateToBase }}</td>
    <td :class="setclass()" style="text-align: right">
      {{ profitInEur }}
    </td>
    <td style="text-align: right" v-show="isDeleteVisible">
      <button
        class="btn btn-danger btn-sm"
        @click="deleteSelectedId($event, guid)"
      >
        <font-awesome-icon icon="fa-solid fa-solid fa-trash" />
      </button>
    </td>
  </tr>
</template>

<script>
import appUtils from '../utils/appUtils';

export default {
  props: [
    'guid',
    'selectedguid',
    'symbol',
    'description',
    'buySell',
    'tradeDate',
    'quantity',
    'tradePrice',
    'currency',
    'tradeMoney',
    'showActive',
    'dateFormated',
    'fifoPnlRealized',
    'isDeleteVisible',
    'fxRateToBase',
  ],
  //emits: ['select-id', 'update:active', 'updateradio'],
  emits: ['select-id', 'delete-id'],

  data() {
    // if (this.$props.active) {
    //   console.log('TRUE');
    // } else {
    //   console.log('FALSE');
    // }

    return { isActive: true };
  },

  methods: {
    // formatCurrency(parNumber) {
    //   return appUtils.formatCurrency(parNumber);
    // },

    // toggleRadio(event) {
    //   this.$emit('updateradio', [event.target.checked, this.active, this.guid]);
    // },

    isNumber(value) {
      return typeof value === 'number' && !isNaN(value);
    },

    deleteSelectedId(event, value) {
      this.$emit('delete-id', value);
    },

    setSelectedId(event, value) {
      this.$emit('select-id', {
        guid: value,
        description: this.description,
        symbol: this.symbol,
      });
    },

    setclass() {
      {
        return {
          isActivejr: this.selectedguid === this.guid ? true : false,
        };
      }
    },
  },

  computed: {
    getComputed123() {
      return '';
    },

    getFormatedDate() {
      return appUtils.getFormatedDate(this.dateFormated);
    },

    profitInEur() {
      //return this.fifoPnlRealized * this.fxRateToBase;

      if (
        this.isNumber(this.fifoPnlRealized) &&
        this.isNumber(this.fxRateToBase)
      ) {
        return appUtils.fixCurrency(this.fifoPnlRealized * this.fxRateToBase);
      }

      return 0;
    },

    // isDeleteVisible() {
    //   return this.isDeleteVisible;
    // },
  },
};
</script>

<style scoped>
li {
  margin: 0.5rem 0;
  border: 1px solid #424242;
  border-radius: 4px;
  padding: 0.75rem;
}

h3 {
  font-size: 1.3rem;
}

h3,
h4 {
  margin: 0.5rem 0;
}

div {
  margin: 0.3rem 0;
}

.actions {
  display: flex;
  justify-content: flex-end;
}
</style>
